import { memo } from 'react';
import { Breadcrumb } from 'antd';
import { variables } from 'utils/variables';

const Breadcrumbs = ({ items }) => {
  const breadcrumbItems = items.map((item) => ({ title: item }));

  return <Breadcrumb style={{ marginBottom: variables.spacing.xxs }} separator=" / " items={breadcrumbItems} />;
};

export default memo(Breadcrumbs);
