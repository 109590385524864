import styled from 'styled-components';
import { StarFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { variables } from 'utils/variables';
import { colors } from 'utils/colors';

const UserName = styled.span`
  font-weight: 500;
  font-size: ${variables.fontSize.title.xs}px;
  color: ${colors.PRIMARY};
`;

const IntroInfo = styled.div`
  margin-top: ${variables.spacing.xs}px;
`;

const CounterContainer = styled.div`
  margin-left: ${variables.spacing.xs}px;
  text-align: right;
`;

const CounterDescription = styled.small`
  display: block;
  font-size: ${variables.fontSize.body.xxs}px;
`;

const TooltipTrigger = styled.span`
  cursor: pointer;
  margin-left: ${variables.spacing.xs}px;
`;

const DashboardCta = styled(Button)`
  height: auto;
  padding: ${variables.spacing.xxs}px ${variables.spacing.s}px;
  margin-top: ${variables.spacing.xs}px;
  margin-bottom: ${variables.spacing.s}px;
`;

const StyledStar = styled(StarFilled)`
  color: ${colors.WARNING};
  margin-right: ${variables.spacing.xxs};
`;

export { UserName, IntroInfo, CounterContainer, CounterDescription, TooltipTrigger, DashboardCta, StyledStar };
