import styled from 'styled-components';
import { palette, colors } from 'utils/colors';
import breakpoints from 'utils/breakpoints';
import { variables } from 'utils/variables';

const StyledTitle = styled.h2`
  color: ${palette[colors.GREY][800]};
  margin-top: ${variables.spacing.xxs}px;
  margin-bottom: ${variables.spacing.xxs}px;

  span {
    font-size: ${variables.fontSize.body.default}px;
  }

  @media (min-width: ${breakpoints.MD}px) {
    margin-top: ${variables.spacing.m}px;
    margin-bottom: ${variables.spacing.s}px;

    span {
      font-size: ${variables.fontSize.title.xs}px;
    }
  }
`;

export default StyledTitle;
