const TooltipContent = ({ title, items, viewsLabel }) => {
  return (
    <div>
      <h5>{title}</h5>
      {items.map((item) => (
        <p key={item.title + item.value}>
          {viewsLabel}: {item.value}
        </p>
      ))}
    </div>
  );
};

export default TooltipContent;
