import styled from 'styled-components';
import { colors } from 'utils/colors';
import { variables } from 'utils/variables';

const CardSmallInfo = styled.span`
  display: block;
  color: ${colors.BLACK};
  font-size: ${variables.fontSize.body.s}px;
  line-height: 1.4;
  margin-bottom: ${variables.spacing.s}px;
`;

export { CardSmallInfo };
