import styled from 'styled-components';
import breakpoints from '../../utils/breakpoints';

const SidebarWrapper = styled.div`
  display: none;

  @media (min-width: ${breakpoints.MD}px) {
    display: block;
  }
`;

export default SidebarWrapper;
