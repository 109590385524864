// !TODO
import errorCodes from 'utils/errorCodes';
import responseCodes from '../../utils/responseCodes';

export const getAlertsStats = async (token, logoutAction) => {
  return await getData(token, 'https://lubimyczytac.pl/publisher_data/api/alerts', logoutAction);
};

const getData = async (token, url, logoutAction) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      body: `token=${token}`,
    });

    if (response.ok) {
      const data = await response.json();
      if (data && data.code === responseCodes.OK) {
        return data.data?.stats;
      } else if (data && data.code === responseCodes.ERROR && data.data.ERR_CODE === errorCodes.SESSION_EXPIRED) {
        logoutAction();
      }
      return null;
    } else {
      console.error('Błąd pobierania danych!');
    }
  } catch (error) {
    console.error(`Wystąpił błąd podczas pobierania danych z adresu ${url}:`, error);
  }
};
