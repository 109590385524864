import styled from 'styled-components';

import { variables } from 'utils/variables';

const RankInfo = styled.div`
  margin-bottom: ${variables.spacing.s}px;
`;

const TooltipTrigger = styled.span`
  cursor: pointer;
`;

export { RankInfo, TooltipTrigger };
