import { Layout } from 'antd';
import { variables } from 'utils/variables';
import { Outlet } from 'react-router-dom';
import HeaderTop from '../../components/Header/HeaderTop';
import Sidebar from '../../components/Sidebar/Sidebar';
import FooterBottom from 'components/Footer/FooterBottom';

const LayoutPage = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <HeaderTop />
      <Layout hasSider>
        <Sidebar />
        <Layout style={{ padding: variables.spacing.GUTTER }}>
          <Outlet />
          <FooterBottom />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default LayoutPage;
