import { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { Alert, Col, Layout, Row, Tabs, Input, Tooltip, Space } from 'antd';
import { Bar } from '@ant-design/plots';
import { BookOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { LoginContext } from 'pages/Router';
import { variables } from 'utils/variables';
import { getComparePublisherStats } from 'api/services/StatsPublisherService';
import { colors, palette } from '../../utils/colors';
import CardTile from '../../components/CardTile/CardTile';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import RankBarChart from '../../components/RankBarChart/RankBarChart';
import TooltipContent from '../../components/TooltipContent/TooltipContent';
import TitleSection from '../../components/TitleSection/TitleSection';
import CustomTable from 'components/CustomTable/CustomTable';
import useColumnSearchProps from 'helpers/CustomTableSorter';
import SmallNote from 'components/SmallNote/SmallNote';
import { TooltipTrigger } from '../Dashboard/DashboardPageStyles';

const { Content } = Layout;

const TabWithSearch = ({ tabItems }) => {
  const [searchQuery, setSearchQuery] = useState('');

  // Filter the tab items based on the search query
  const filteredTabs = tabItems.filter((item) => item.label.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <div>
      <Input placeholder="Wyszukaj kategorię, którą jesteś zainteresowany..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} style={{ marginBottom: '16px' }} />

      <Tabs
        defaultActiveKey="1"
        items={filteredTabs.map((item, i) => {
          const id = String(i + 1);
          return {
            label: (
              <span>
                {item.icon}
                {item.label}
              </span>
            ),
            key: id,
            children: item.children,
          };
        })}
      />
    </div>
  );
};

const DashboardPage = () => {
  const { token, logout, publisher } = useContext(LoginContext);

  const [comparePublisherStats, setComparePublisherStats] = useState({});
  const [comparePublisherStatsLoading, setComparePublisherStatsLoading] = useState(true);

  useEffect(() => {
    getComparePublisherStats(token, logout)
      .then((data) => {
        setComparePublisherStats(data);
      })
      .finally(() => {
        setComparePublisherStatsLoading(false);
      });
  }, [logout, token]);

  // Wykres - top wydawnictw wg ocen ksiazek
  const publishersByBookRating = comparePublisherStats?.top_publishers_by_book_rating_amount;

  let publishersByBookRatingDataChart = [];
  if (publishersByBookRating) {
    publishersByBookRatingDataChart = publishersByBookRating?.list?.map((publisher) => {
      return {
        nazwa: publisher?.name,
        pozycja: parseInt(publisher?.rating_counter),
      };
    });
  }

  const configForBookRating = {
    data: publishersByBookRatingDataChart,
    xField: 'pozycja',
    yField: 'nazwa',
    xAxis: {
      label: {
        formatter: (v) => `${v} książek`,
      },
    },
    yAxis: {
      label: null,
    },
    seriesField: 'nazwa',
    color: ({ nazwa }) => {
      return nazwa === publisher.toString() ? colors.PRIMARY : palette[colors.VIOLET][300];
    },
    legend: false,
    tooltip: {
      customContent: (title, items) => {
        return <TooltipContent title={title} items={items} viewsLabel={'Ocenionych książek'}></TooltipContent>;
      },
    },
    columnStyle: {
      radius: [10, 10, 7, 7],
    },
  };

  // Wykres - top wydawnictw wg przeczytanych ksiazek
  const publishersByBookRead = comparePublisherStats?.top_publishers_by_book_read_amount;

  let publishersByBookReadDataChart = [];
  if (publishersByBookRead) {
    publishersByBookReadDataChart = publishersByBookRead?.list?.map((publisher) => {
      return {
        nazwa: publisher?.name,
        pozycja: parseInt(publisher?.books_read_counter),
      };
    });
  }

  const configForBookRead = {
    data: publishersByBookReadDataChart,
    xField: 'pozycja',
    yField: 'nazwa',
    xAxis: {
      label: {
        formatter: (v) => `${v} książek`,
      },
    },
    yAxis: {
      label: null,
    },
    seriesField: 'nazwa',
    color: ({ nazwa }) => {
      return nazwa === publisher.toString() ? colors.PRIMARY : palette[colors.VIOLET][300];
    },
    tooltip: {
      customContent: (title, items) => {
        return <TooltipContent title={title} items={items} viewsLabel={'Przeczytanych książek'}></TooltipContent>;
      },
    },
    legend: false,
  };

  // Wykres - top wydawnictw wg ilości opinii
  const publishersByReviews = comparePublisherStats?.top_publishers_by_book_reviews_amount;

  let publishersByReviewsDataChart = [];
  if (publishersByReviews) {
    publishersByReviewsDataChart = publishersByReviews?.list?.map((publisher) => {
      return {
        nazwa: publisher?.name,
        pozycja: parseInt(publisher?.reviews_counter),
      };
    });
  }

  const configForReviews = {
    data: publishersByReviewsDataChart,
    legend: {
      position: 'bottom-left',
      padding: [20, 0, 0, 0],
    },
    xField: 'pozycja',
    yField: 'nazwa',
    // color: ['#1ca9e6', '#f88c24'],
    xAxis: {
      label: {
        formatter: (v) => `${v} opinii`,
      },
    },
    yAxis: {
      label: null,
      // label: {
      //   autoHide: false,
      //   autoRotate: true,
      // },
      meta: {
        min: 0,
        minHeight: 350,
      },
    },
    seriesField: 'nazwa',
    color: ({ nazwa }) => {
      return nazwa === publisher.toString() ? colors.PRIMARY : palette[colors.VIOLET][300];
    },
    tooltip: {
      customContent: (title, items) => {
        return <TooltipContent title={title} items={items} viewsLabel={'Wystawionych opinii'}></TooltipContent>;
      },
    },
  };

  // Wykresy - top 10 wg kategorii
  const getCategoryData = useCallback((data) => {
    if (!data) {
      return null;
    }
    return data.map((item) => {
      return {
        name: item.name,
        books_read_counter: parseInt(item.books_read_counter),
      };
    });
  }, []);

  const getConfigForCategory = useCallback(
    (categoryData) => {
      return {
        data: categoryData,
        xField: 'books_read_counter',
        yField: 'name',
        // yAxis: {
        //   label: null,
        // },
        seriesField: 'name',
        color: ({ name }) => {
          return name === publisher.toString() ? colors.PRIMARY : palette[colors.VIOLET][300];
        },
        tooltip: {
          customContent: (title, items) => {
            return <TooltipContent title={title} items={items} viewsLabel={'Liczba użytkowników, którzy przeczytali książki w kategorii'}></TooltipContent>;
          },
        },
        legend: false,
      };
    },
    [publisher],
  );

  const tabItems = useMemo(() => {
    const items = [];
    for (const id in comparePublisherStats?.top_publishers_by_book_in_category_read_amount?.list) {
      const { category, data } = comparePublisherStats?.top_publishers_by_book_in_category_read_amount?.list[id];
      items.push({
        label: category.name,
        icon: <BookOutlined style={{ marginRight: variables.spacing.xs }} />,
        children: <Bar {...getConfigForCategory(getCategoryData(data))} />,
      });
    }
    return items;
  }, [comparePublisherStats?.top_publishers_by_book_in_category_read_amount?.list, getCategoryData, getConfigForCategory]);

  const breadcrumbItems = useMemo(() => ['Home', 'Dla wydawców', 'Panel wydawców'], []);

  // Tabelka - porownanie wydawnictwa w swojej kategorii

  const { getColumnSearchProps } = useColumnSearchProps();

  const apiToTableMap = {
    publisher_name: 'Nazwa wydawcy', // column1
    category_name: 'Nazwa kategorii', // column2
    book_count: 'Liczba książek', // column3
    avg_book_count: 'Średnia ilość książek konkurencji', // column4
    median_book_count: 'Mediana ilości książek konkurencji', // column5
    avg_rating: 'Średnia ocena książek', // column6
    avg_avg_rating: 'Średnia ocena książek konkurencji', // column7
    total_rating_votes: 'Liczba ocen', // column8
    avg_total_rating_votes: 'Średnia ilość ocen konkurencji', // column9
    median_total_rating_votes: 'Mediana ilości ocen konkurencji', // column10
    total_reviews_amount: 'Liczba opinii', // column11
    avg_total_reviews_amount: 'Średnia ilość opinii konkurencji', // column12
    median_total_reviews_amount: 'Mediana ilości opinii konkurencji', // column13
  };

  let publishersInCategoriesTableSource = comparePublisherStats?.publishers_by_category_comparison?.list ?? [];

  if (publishersInCategoriesTableSource) {
    const formatValue = (value) => {
      return value !== '-' && !isNaN(parseFloat(value)) ? parseFloat(value).toFixed(2) : '-';
    };

    const formatValueRoundUp = (value) => {
      return value !== '-' && !isNaN(parseFloat(value)) ? Math.ceil(parseFloat(value)) : '-';
    };

    publishersInCategoriesTableSource = publishersInCategoriesTableSource.map((item, index) => ({
      key: `column${index}`,
      column1: item.publisher_name, // Nazwa wydawcy
      column2: item.category_name, // Nazwa kategorii
      column3: item.book_count, // Liczba książek
      column4: formatValueRoundUp(item.avg_book_count), // Średnia ilość książek konkurencji
      //column5: formatValueRoundUp(item.median_book_count), // Mediana ilości książek konkurencji
      column6: formatValue(item.avg_rating), // Średnia ocena książek
      column7: formatValue(item.avg_avg_rating), // Średnia ocena książek konkurencji
      column8: item.total_rating_votes, // Liczba ocen
      column9: formatValueRoundUp(item.avg_total_rating_votes), // Średnia ilość ocen konkurencji
      //column10: formatValueRoundUp(item.median_total_rating_votes), // Mediana ilości ocen konkurencji
      column11: item.total_reviews_amount, // Liczba opinii
      column12: formatValueRoundUp(item.avg_total_reviews_amount), // Średnia ilość opinii konkurencji
      //column13: formatValueRoundUp(item.median_total_reviews_amount), // Mediana ilości opinii konkurencji
      isHighlighted: item.publisher_name === publisher,
    }));
  }

  const columns = [
    {
      title: apiToTableMap['publisher_name'],
      dataIndex: 'column1',
      key: 'column1',
      width: 200,
      sorter: (a, b) => a.column1.localeCompare(b.column1),
      ...getColumnSearchProps('column1'),
      fixed: 'left',
    },
    {
      title: apiToTableMap['category_name'],
      dataIndex: 'column2',
      key: 'column2',
      width: 200,
      sorter: (a, b) => a.column2.localeCompare(b.column2),
      ...getColumnSearchProps('column2'),
    },
    {
      title: apiToTableMap['book_count'],
      dataIndex: 'column3',
      key: 'column3',
      width: 150,
      sorter: (a, b) => a.column3 - b.column3,
    },
    {
      title: apiToTableMap['avg_book_count'],
      dataIndex: 'column4',
      key: 'column4',
      width: 180,
      sorter: (a, b) => a.column4 - b.column4,
    },
    //{
    //title: apiToTableMap['median_book_count'],
    //dataIndex: 'column5',
    //key: 'column5',
    //width: 180,
    //sorter: (a, b) => a.column5 - b.column5,
    //},
    {
      title: apiToTableMap['avg_rating'],
      dataIndex: 'column6',
      key: 'column6',
      width: 180,
      sorter: (a, b) => a.column6 - b.column6,
    },
    {
      title: apiToTableMap['avg_avg_rating'],
      dataIndex: 'column7',
      key: 'column7',
      width: 180,
      sorter: (a, b) => a.column7 - b.column7,
    },
    {
      title: apiToTableMap['total_rating_votes'],
      dataIndex: 'column8',
      key: 'column8',
      width: 180,
      sorter: (a, b) => a.column8 - b.column8,
    },
    {
      title: apiToTableMap['avg_total_rating_votes'],
      dataIndex: 'column9',
      key: 'column9',
      width: 180,
      sorter: (a, b) => a.column9 - b.column9,
    },
    //{
    //title: apiToTableMap['median_total_rating_votes'],
    //dataIndex: 'column10',
    //key: 'column10',
    //width: 180,
    //sorter: (a, b) => a.column10 - b.column10,
    //},
    {
      title: apiToTableMap['total_reviews_amount'],
      dataIndex: 'column11',
      key: 'column11',
      width: 180,
      sorter: (a, b) => a.column11 - b.column11,
    },
    {
      title: apiToTableMap['avg_total_reviews_amount'],
      dataIndex: 'column12',
      key: 'column12',
      width: 180,
      sorter: (a, b) => a.column12 - b.column12,
    },
    //{
    // title: apiToTableMap['median_total_reviews_amount'],
    // dataIndex: 'column13',
    //key: 'column13',
    //width: 180,
    //sorter: (a, b) => a.column13 - b.column13,
    //},
  ];

  const rowClassName = (record) => {
    return record.isHighlighted ? 'ant-table-row--highlighted' : '';
  };

  return (
    <Content>
      <Breadcrumbs items={breadcrumbItems} />

      <Row>
        <Col xs={24}>
          <Space>
            <TitleSection title={`Wydawnictwo "${publisher}" vs. liderzy rynku wydawniczego`} />
            <Tooltip title="Sprawdź, które wydawnictwa mają największą liczbę czytelników w poszczególnych kategoriach. Zobacz, jak plasuje się Twoje wydawnictwo w rankingu i zyskaj cenne informacje na temat najpopularniejszych ofert na rynku. Wykorzystaj te dane, aby skuteczniej planować swoją strategię wydawniczą i tworzyć atrakcyjne propozycje dla czytelników.">
              <TooltipTrigger>
                <InfoCircleOutlined />
              </TooltipTrigger>
            </Tooltip>
          </Space>
        </Col>
      </Row>

      <Row style={{ gap: 24 }}>
        <Col xs={24}>
          <CardTile loading={comparePublisherStatsLoading} title={`"${publisher}" na tle innych wydawnictw publikujących książki w tych samych kategoriach`}>
            <TabWithSearch tabItems={tabItems} />
            <SmallNote
              note="Ranking uwzględnia 10 wydawnictw cieszących się największym zainteresowaniem wśród użytkowników w serwisie. Jeżeli nie widzisz swojego wydawnictwa w zestawieniu, oznacza to, że jest poza rankingiem. Zachęcamy do kontaktu z działem sprzedaży reklam, nasi eksperci chętnie przedstawią możliwości optymalizacji statystyk poprzez skorzystanie z naszych usług reklamowych."
              footnote
            />
          </CardTile>
        </Col>

        <Col xs={24}>
          <CardTile
            title={
              <>
                {`"${publisher}" na tle innych wydawnictw publikujących w tych samych kategoriach`}
                <Tooltip title="Zidentyfikuj obszary, w których konkurencja jest silniejsza, i podejmij działania, aby wyrównać lub przewyższyć ich wyniki. Rozważ dostosowanie strategii cenowej, marketingowej lub wydawniczej, aby zwiększyć swoją pozycję na rynku i przyciągnąć więcej czytelników. Skutecznie zarządzaj reputacją swojego wydawnictwa, wykorzystując twarde dane, które pomogą Ci podejmować bardziej świadome decyzje strategiczne.">
                  <TooltipTrigger>
                    <InfoCircleOutlined />
                  </TooltipTrigger>
                </Tooltip>
              </>
            }
          >
            <CustomTable
              columns={columns}
              dataSource={publishersInCategoriesTableSource}
              loading={comparePublisherStatsLoading}
              pagination={{ pageSize: 15, showSizeChanger: false, showTotal: (total, range) => `${range[0]}-${range[1]} z ${total}` }}
              scroll={{ x: 700, y: 380 }}
              rowClassName={rowClassName}
            />
          </CardTile>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <Space>
            <TitleSection title={`Miejsce wydawnictwa ${publisher} w rankingu popularności wydawców wśród społeczności`} />
            <Tooltip title="Sprawdź, które wydawnictwa cieszą się największą popularnością wśród czytelników w Polsce. Dowiedz się, czy Twoje wydawnictwo znajduje się wśród liderów i jak daleko jest od czołówki. Wykorzystaj te dane, aby uzyskać szeroki obraz rynku wydawniczego i lepiej zrozumieć, gdzie plasujesz się w skali globalnej. Analizuj te informacje, aby wyznaczać długoterminowe cele i dostosowywać swoje strategie rozwoju, dążąc do zwiększenia popularności i zasięgu swojego wydawnictwa.">
              <TooltipTrigger>
                <InfoCircleOutlined />
              </TooltipTrigger>
            </Tooltip>
          </Space>
        </Col>
        <Col xs={24}>
          <Alert
            style={{ marginBottom: variables.spacing.s }}
            message="Podpowiedź"
            description="Aby zobaczyć nazwy wydawnictw i szczegółowe dane najedź kursorem na wykres lub dotknij ekran urządzenia."
            type="info"
            showIcon
            closable
          />
        </Col>
      </Row>
      <Row gutter={[variables.spacing.GUTTER, variables.spacing.GUTTER]}>
        <Col xs={24} xl={12}>
          <RankBarChart
            statsLoading={comparePublisherStatsLoading}
            charTitle="Liczba książek ocenionych przez społeczność Lubimyczytać"
            rank={publishersByBookRating?.rank}
            configRating={configForBookRating}
            publisher={publisher}
          />
        </Col>
        <Col xs={24} xl={12}>
          <RankBarChart
            statsLoading={comparePublisherStatsLoading}
            charTitle="Liczba książek przeczytanych przez społeczność Lubimyczytać"
            rank={publishersByBookRead?.rank}
            configRating={configForBookRead}
            publisher={publisher}
          />
        </Col>
        <Col xs={24} xl={12}>
          <RankBarChart publisher={publisher} statsLoading={comparePublisherStatsLoading} charTitle="Liczba opinii do książek" rank={publishersByReviews?.rank} configRating={configForReviews} />
        </Col>
      </Row>
    </Content>
  );
};

export default DashboardPage;
