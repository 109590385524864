import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Row, notification } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { LoginPageWrapper, LoginWrapper, FormField, FormButton, Logo, LoginTitle } from './LoginPageStyles';
import { logIn } from '../../api/services/LoginService';
import { LoginContext } from '../Router';
import logo from '../../assets/images/lc-logo-panel.svg';
import responseCodes from '../../utils/responseCodes';

const LoginPage = () => {
  const navigate = useNavigate();
  const { setToken, manageUserInfo } = useContext(LoginContext);

  const onLogIn = async (values) => {
    const response = await logIn(values);
    if (!response || response.code !== responseCodes.OK) {
      if (response.code === responseCodes.ERROR) {
        notification.error({
          message: 'Błąd logowania',
          description: `Wystąpił błąd podczas logowania. ${response.msg}`,
        });
      }
    } else {
      manageUserInfo(response.data.authToken.contact.fullname, response.data.authToken.publisher.name, response.data.authToken.data_access_level, response.data.authToken.publisher.url);
      setToken(response.data.authToken.auth_token);
      navigate('/');
    }
  };
  return (
    <LoginPageWrapper>
      <LoginWrapper xs={22} md={16} lg={8}>
        <Row>
          <LoginTitle>Panel wydawców</LoginTitle>
        </Row>
        <Form className="form" name="normal_login" initialValues={{ remember: true }} onFinish={onLogIn}>
          <Form.Item name="login" rules={[{ required: true, message: 'Proszę wpisać login' }]}>
            <FormField className="form__field" prefix={<UserOutlined />} placeholder="Login" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: 'Proszę wpisać hasło' }]}>
            <FormField className="form__field" prefix={<LockOutlined />} type="password" placeholder="Hasło" />
          </Form.Item>
          <Form.Item>
            <FormButton type="primary" htmlType="submit" className="form__cta">
              Zaloguj się
            </FormButton>
          </Form.Item>
        </Form>
        <Logo src={logo} alt="lubimyczytac.pl" className="login-page__logo" />
      </LoginWrapper>
    </LoginPageWrapper>
  );
};

export default LoginPage;
