import responseCodes from '../../utils/responseCodes';

export const getLcStats = async (logoutAction) => {
  return await getData('https://lubimyczytac.pl/lc_data/api/stats', logoutAction);
};

const getData = async (url) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
    });

    if (response.ok) {
      const data = await response.json();
      if (data && data.code === responseCodes.OK) {
        return data.data?.stats;
      }
      return null;
    } else {
      console.error('Błąd pobierania danych!');
    }
  } catch (error) {
    console.error(`Wystąpił błąd podczas pobierania danych z adresu ${url}:`, error);
  }
};
