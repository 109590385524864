import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { variables } from '../../utils/variables';
import breakpoints from '../../utils/breakpoints';

const HeaderContainer = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: ${variables.spacing.GUTTER}px;
  padding-left: ${variables.spacing.GUTTER}px;
  padding-top: ${variables.spacing.xxs}px;
  padding-bottom: ${variables.spacing.xxs}px;
  background-color: ${colors.WHITE};
  z-index: ${variables.zIndex.header};
  box-shadow:
    0px 2px 32px -1px rgba(0, 0, 0, 0.05),
    0px 4px 5px 0px rgba(0, 0, 0, 0.03),
    0px 1px 10px 0px rgba(0, 0, 0, 0.001);
  color: ${colors.BLACK};
`;

const LogoImageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  aspect-ratio: 209 / 36;
  max-width: 150px;

  @media (min-width: ${breakpoints.MD}px) {
    max-width: 180px;
  }
`;

const HeaderMenu = styled.div`
  display: block;

  @media (min-width: ${breakpoints.MD}px) {
    display: none;
  }
`;

const UserMenuName = styled.span`
  display: none;
  max-width: fit-content;
  margin-left: ${variables.spacing.xs}px;

  @media (min-width: ${breakpoints.MD}px) {
    display: inline;
  }
`;

export { HeaderContainer, LogoImageWrapper, LogoImage, HeaderMenu, UserMenuName };
