const colors = {
  PRIMARY: '#f50',
  WARNING: '#ffb800',
  NEGATIVE: '#f60f1d',
  POSITIVE: '#45db1d',
  TEAL: '#28dec8',
  BLUE: '#00b2ff',
  VIOLET: '#5030f2',
  PINK: '#f525a9',
  GREY: '#888',
  WHITE: '#fff',
  BLACK: '#000',
  BLUEGREY: '#9c9fa1',
  LIGHTGREY: '#fafafa',
};

const palette = {
  [colors.PRIMARY]: {
    100: '#fdc',
    200: '#fb9',
    300: '#f96',
    400: '#f73',
    500: colors.PRIMARY,
    600: '#c40',
    700: '#930',
    800: '#620',
    900: '#310',
  },
  [colors.WARNING]: {
    100: '#fff4d9',
    200: '#ffe8ad',
    300: '#ffd873',
    400: '#ffca40',
    500: colors.WARNING,
    600: '#cc9300',
    700: '#996e00',
    800: '#664a00',
    900: '#402e00',
  },
  [colors.NEGATIVE]: {
    100: '#fedbdd',
    200: '#fdb2b7',
    300: '#fb7b83',
    400: '#f94b55',
    500: colors.NEGATIVE,
    600: '#c60c17',
    700: '#940911',
    800: '#63060c',
    900: '#3e0407',
  },
  [colors.POSITIVE]: {
    100: '#e3fadd',
    200: '#c3f4b7',
    300: '#99eb83',
    400: '#73e455',
    500: colors.POSITIVE,
    600: '#37af17',
    700: '#298311',
    800: '#1c580c',
    900: '#113707',
  },
  [colors.TEAL]: {
    100: '#dffaf7',
    200: '#baf4ed',
    300: '#89ede1',
    400: '#5ee6d6',
    500: colors.TEAL,
    600: '#20b2a0',
    700: '#188578',
    800: '#105950',
    900: '#0a3832',
  },
  [colors.BLUE]: {
    100: '#d9f4ff',
    200: '#ade7ff',
    300: '#73d5ff',
    400: '#40c6ff',
    500: colors.BLUE,
    600: '#008fcc',
    700: '#006b99',
    800: '#004866',
    900: '#002d40',
  },
  [colors.VIOLET]: {
    100: '#e5e0fd',
    200: '#c7bdfb',
    300: '#9f8df8',
    400: '#7c64f5',
    500: colors.VIOLET,
    600: '#4026c2',
    700: '#301d91',
    800: '#201361',
    900: '#140c3d',
  },
  [colors.PINK]: {
    100: '#fedef3',
    200: '#fcb9e3',
    300: '#fa87d2',
    400: '#f85cbf',
    500: colors.PINK,
    600: '#c41e87',
    700: '#931665',
    800: '#620f45',
    900: '#3d092a',
  },
  [colors.GREY]: {
    100: '#eee',
    200: '#cbcbcb',
    300: '#b5b5b5',
    400: '#9e9e9e',
    500: colors.GREY,
    600: '#6f6f6f',
    700: '#555',
    800: '#3d3d3d',
    900: '#222',
  },
};

export { colors, palette };
