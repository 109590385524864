import { useState, useCallback, useMemo, useContext } from 'react';
import { Button, Modal, Space } from 'antd';
import { Line } from '@ant-design/plots';
import { PieChartOutlined } from '@ant-design/icons';
import { colors } from 'utils/colors';
import messages from 'utils/messages';
import { LoginContext } from 'pages/Router';
import { getPublisherBookViewsStats } from '../../api/services/StatsPublisherService';
import TooltipContent from '../TooltipContent/TooltipContent';
import TimeFrameTag from 'components/TimeFrameTag/TimeFrameTag';
import { dateRanges, dateRangeValues } from 'utils/dateRanges';

const ChartButton = ({ bookId, modalTitle, timeFrame }) => {
  const [visible, setVisible] = useState(false);
  const [isError, setIsError] = useState(false);
  const { token, logout } = useContext(LoginContext);
  const [data, setData] = useState([]);

  const titleWithTimeFrame = (
    <Space wrap>
      {modalTitle}
      <TimeFrameTag timeFrame={timeFrame} />
    </Space>
  );

  const lineChart = useCallback((timelineData) => {
    const timeline = timelineData
      .map((item) => {
        return {
          time: item.created_at.substring(0, 10),
          wyświetlenia: parseInt(item.page_views),
        };
      })
      .reverse();
    const config = {
      data: timeline,
      height: 150,
      xField: 'time',
      yField: 'wyświetlenia',
      label: {},
      point: {
        size: 5,
        shape: 'circle',
        style: {
          fill: 'white',
          stroke: colors.PRIMARY,
          lineWidth: 2,
        },
      },
      tooltip: {
        showMarkers: false,
        customContent: (title, items) => {
          return <TooltipContent title={title} items={items} viewsLabel={'Wyświetlenia'}></TooltipContent>;
        },
      },
      state: {
        active: {
          style: {
            shadowColor: colors.PRIMARY,
            shadowBlur: 4,
            stroke: 'transparent',
            fill: colors.PRIMARY,
          },
        },
      },
      lineStyle: {
        stroke: colors.PRIMARY,
        lineWidth: 4,
        lineJoin: 'round',
        lineCap: 'round',
      },
      theme: {
        geometries: {
          point: {
            diamond: {
              active: {
                style: {
                  shadowColor: colors.PRIMARY,
                  shadowBlur: 2,
                  stroke: colors.PRIMARY,
                },
              },
            },
          },
        },
      },
      interactions: [{ type: 'marker-active' }],
    };
    return <Line {...config} />;
  }, []);

  const fetchData = useCallback(async () => {
    const response = await getPublisherBookViewsStats(token, logout, bookId, dateRangeValues[dateRanges.HALF_YEAR]);
    if (response === null) {
      setIsError(true);
    }
    setData(response);
  }, [logout, token, bookId]);

  const chart = useMemo(() => {
    return lineChart(data);
  }, [lineChart, data]);

  const onClick = useCallback(() => {
    setIsError(false);
    setVisible(true);
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Button type="primary" icon={<PieChartOutlined />} size="small" onClick={() => onClick()}>
        Rozkład ruchu
      </Button>
      <Modal
        width={900}
        title={titleWithTimeFrame}
        open={visible}
        okText="Zamknij okno"
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        {isError ? <p>{messages.errorFetchData}</p> : data ? chart : <p>Ładowanie danych...</p>}
      </Modal>
    </>
  );
};

export default ChartButton;
