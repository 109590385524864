import { ConfigProvider } from 'antd';
import plPL from 'antd/locale/pl_PL';
import fonts from 'utils/fonts';
import App from './App';
import { colors, palette } from './utils/colors';
import { variables } from './utils/variables';

const AntdConfigApp = () => (
  <ConfigProvider
    locale={plPL}
    theme={{
      token: {
        colorPrimary: colors.PRIMARY,
        borderRadius: 10,
        borderRadiusSM: 10,
        borderRadiusXS: 8,
        colorLink: colors.BLACK,
        colorLinkHover: colors.PRIMARY,
        colorText: colors.BLACK,
        colorBgContainer: colors.WHITE,
        colorBgBase: colors.WHITE,
        controlHeight: 48,
        controlPaddingHorizontal: 140,
        fontSize: variables.fontSize.body.s,
        fontSizeXL: variables.fontSize.body.default,
        fontFamily: fonts.poppins,
        boxShadow: '0 2px 32px -1px rgba(0, 0, 0, 0.05), 0 4px 5px 0 rgba(0, 0, 0, 0.03), 0px 1px 10px 0px rgba(0, 0, 0, 0.001)',
        boxShadowTertiary: '0 4px 8px rgba(0, 0, 0, 0.1)', // cards, segmented
        controlItemBgActive: colors.WHITE,
        controlPaddingHorizontalSM: variables.spacing.xs,
      },
      components: {
        Layout: {
          lightTriggerBg: colors.PRIMARY,
          triggerBg: colors.PRIMARY,
          siderBg: colors.WHITE,
          bodyBg: colors.LIGHTGREY,
          headerBg: colors.WHITE,
          headerColor: colors.BLACK,
          footerBg: 'transparent',
        },
        Button: {
          colorPrimary: colors.PRIMARY,
          algorithm: true,
          fontWeight: 600,
        },
        Input: {
          colorPrimary: colors.BLACK,
          algorithm: true,
        },
        Menu: {
          colorPrimary: colors.PRIMARY,
          itemActiveBg: colors.WHITE,
          itemHoverBg: palette[colors.GREY][100],
          colorBgContainer: colors.WHITE,
          fontSize: variables.fontSize.body.s,
        },
        Statistic: {
          contentFontSize: variables.fontSize.title.s,
        },
        Segmented: {
          itemSelectedBg: colors.PRIMARY,
          itemSelectedColor: colors.WHITE,
        },
        Table: {
          headerBg: '#f2effe',
          headerSortHoverBg: palette[colors.VIOLET][100],
          fixedHeaderSortActiveBg: palette[colors.VIOLET][100],
          headerSortActiveBg: palette[colors.VIOLET][100],
          bodySortBg: '#f9f8fe',
          rowHoverBg: '#f4f2fe',
        },
      },
    }}
  >
    <App />
  </ConfigProvider>
);

export default AntdConfigApp;
