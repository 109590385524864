import { useContext, useEffect, useState, useRef } from 'react';
import Highlighter from 'react-highlight-words';
import CountUp from 'react-countup';
import { Button, Col, Layout, Row, Statistic, Table, Space, Input, Tooltip } from 'antd';
import { RiseOutlined, SearchOutlined, CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Column } from '@ant-design/plots';
import CardTile from 'components/CardTile/CardTile';
import TitleSection from 'components/TitleSection/TitleSection';
import { LoginContext } from 'pages/Router';
import { variables } from 'utils/variables';
import { colors, palette } from 'utils/colors';
import { getAlertsStats } from 'api/services/AlertService';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import SmallNote from 'components/SmallNote/SmallNote';

const { Content } = Layout;

const AlertsPage = () => {
  const [alertsStats, setAlertsStats] = useState({});
  const [alertsStatsLoading, setAlertsStatsLoading] = useState(true);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const { logout, token, publisher } = useContext(LoginContext);

  useEffect(() => {
    getAlertsStats(token, logout)
      .then((data) => {
        setAlertsStats(data);
      })
      .finally(() => {
        setAlertsStatsLoading(false);
      });
  }, [token, logout]);

  const formatter = (value) => <CountUp end={value} separator=" " />;

  // Wykres
  const publishersCompare = alertsStats?.alerts_set?.publishers;

  let chartData = [];
  if (publishersCompare) {
    chartData = publishersCompare.map((alerts) => {
      return {
        nazwa: `${alerts.name ? alerts.name : 'Inne wydawnictwo: ' + parseInt(alerts.percentage_rank * 100) + '%'}`,
        procent: parseInt(alerts.percentage_rank * 100),
      };
    });
  }

  const config = {
    data: chartData,
    xField: 'nazwa',
    yField: 'procent',
    color: ({ nazwa }) => {
      return nazwa === publisher.toString() ? colors.PRIMARY : palette[colors.GREY][300];
    },
    formatter: (value) => <div>{value} %</div>,
    legend: true,
    xAxis: {
      label: null,
    },
    yAxis: {
      label: {
        formatter: (v) => `${v} %`,
      },
    },
    tooltip: {
      customContent: (title, items) => {
        return (
          <div>
            <h5>{title}</h5>
            {items.map((item) => (
              <p key={item.dataIndex}>
                {item.name}: {item.value}
              </p>
            ))}
          </div>
        );
      },
    },
  };

  // TABELA
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm({
      closeDropdown: false,
    });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Wyszukaj w tytułach książek`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 110,
            }}
          >
            Szukaj
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Zamknij
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? colors.GREY : undefined,
          width: '30px',
          padding: `0 ${variables.spacing.xs}px`,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: palette[colors.PRIMARY][200],
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const displayTitleFormat = (type) => (type === 'book' ? 'papier' : type);

  // Alerty na oferty
  let dataTableSourceAlertsOffersSet = alertsStats?.alerts_set?.books?.with_offers ?? [];

  if (dataTableSourceAlertsOffersSet) {
    dataTableSourceAlertsOffersSet = dataTableSourceAlertsOffersSet.map((item, index) => ({
      key: `column${index}`,
      column1: item.book_title,
      column2: displayTitleFormat(item.alert_type),
      column3: item.alerts_set_number,
      column4:
        item.active_alerts_today === 'f' ? (
          <span style={{ color: colors.NEGATIVE }}>
            nie <CloseCircleOutlined />
          </span>
        ) : (
          <span style={{ color: colors.POSITIVE }}>
            tak <CheckCircleOutlined style={{ color: colors.POSITIVE }} />
          </span>
        ),
      alertToday: item.active_alerts_today,
    }));
  }

  const columnsAlertsOffers = [
    {
      title: 'Tytuł książki',
      dataIndex: 'column1',
      key: 'column1',
      width: 150,
      ...getColumnSearchProps('column1'),
      sorter: (a, b) => a.column1.localeCompare(b.column1),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Format pubikacji',
      dataIndex: 'column2',
      key: 'column2',
      width: 75,
    },
    {
      title: 'Liczba alertów',
      dataIndex: 'column3',
      key: 'column3',
      width: 75,
      sorter: (a, b) => a.column3 - b.column3,
    },
    {
      title: 'Czy ma dzisiaj alert',
      dataIndex: 'column4',
      key: 'column4',
      width: 75,
      sorter: (a, b) => a.alertToday.localeCompare(b.alertToday),
    },
  ];

  // Alerty bez ofert
  let dataTableSourceAlertsWithoutOffersSet = alertsStats?.alerts_set?.books?.without_offers ?? [];

  if (dataTableSourceAlertsWithoutOffersSet) {
    dataTableSourceAlertsWithoutOffersSet = dataTableSourceAlertsWithoutOffersSet.map((item, index) => ({
      key: `column${index}`,
      column1: item.book_title,
      column2: displayTitleFormat(item.alert_type),
      column3: item.alerts_set_number,
      column4:
        item.active_alerts_today === 'f' ? (
          <span style={{ color: colors.NEGATIVE }}>
            nie <CloseCircleOutlined />
          </span>
        ) : (
          <span style={{ color: colors.POSITIVE }}>
            tak <CheckCircleOutlined style={{ color: colors.POSITIVE }} />
          </span>
        ),
      alertToday: item.active_alerts_today,
    }));
  }

  const columnsAlertsWithoutOffers = [
    {
      title: 'Tytuł książki',
      dataIndex: 'column1',
      key: 'column1',
      width: 150,
      ...getColumnSearchProps('column1'),
      sorter: (a, b) => a.column1.localeCompare(b.column1),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Format pubikacji',
      dataIndex: 'column2',
      key: 'column2',
      width: 75,
    },
    {
      title: 'Liczba powiadomień',
      dataIndex: 'column3',
      key: 'column3',
      width: 75,
      sorter: (a, b) => a.column3 - b.column3,
    },
    {
      title: 'Czy ma dzisiaj alert',
      dataIndex: 'column4',
      key: 'column4',
      width: 75,
      sorter: (a, b) => a.alertToday.localeCompare(b.alertToday),
    },
  ];

  return (
    <Content>
      <Breadcrumbs items={['Home', 'Dla wydawców', 'Alerty']} />

      <Row gutter={[variables.spacing.GUTTER, variables.spacing.GUTTER]} style={{ marginTop: variables.spacing.l }}>
        <Col xs={24} xl={12}>
          <CardTile>
            <span
              style={{
                marginBottom: variables.spacing.xs,
                fontSize: variables.fontSize.title.xs,
                color: colors.PRIMARY,
              }}
            >
              Alerty LC
            </span>{' '}
            ustawiają użytkownicy serwisu na zasadach określonych na podstronie{' '}
            <a target="_blank" rel="noreferrer" href="https://lubimyczytac.pl/alerty">
              lubimyczytac.pl/alerty
            </a>
            <br></br>
            <br></br>
            Dane, które prezentujemy są aktualizowane w cyklach dobowych. Alerty LC bazują na danych od zewnętrznych partnerów i spółka Lubimyczytać.pl nie ponosi odpowiedzialności za ewentualne
            nieścisłości cenowe, które wynikają z różnych czasów aktualizacji cen książek w partnerskich księgarniach. Za ewentualne rozbieżności przepraszamy.
            <Button style={{ display: 'block', marginTop: variables.spacing.s, width: 'fit-content' }} type="primary" href="https://lubimyczytac.pl/alerty" target="_blank">
              Zobacz alerty w serwisie
            </Button>
          </CardTile>
        </Col>
        <Col xs={24} xl={12}>
          <Row gutter={[variables.spacing.GUTTER, variables.spacing.GUTTER]} style={{ height: '100%' }}>
            <Col xs={24} lg={12}>
              <CardTile>
                <Statistic
                  title="Aktywne alerty dzisiaj w serwisie Lubimyczytac.pl"
                  loading={alertsStatsLoading}
                  value={alertsStats?.alerts_active?.number?.total}
                  precision={2}
                  valueStyle={{ color: colors.PRIMARY }}
                  prefix={<RiseOutlined />}
                  formatter={formatter}
                />
              </CardTile>
            </Col>
            <Col xs={24} lg={12}>
              <CardTile>
                <Statistic
                  title="Liczba wszystkich alertów w serwisie Lubimyczytac.pl"
                  loading={alertsStatsLoading}
                  value={alertsStats?.alerts_set?.number?.total}
                  precision={2}
                  valueStyle={{ color: colors.PRIMARY }}
                  prefix={<RiseOutlined />}
                  formatter={formatter}
                />
              </CardTile>
            </Col>
            <Col xs={24} lg={12}>
              <CardTile>
                <Statistic
                  title={
                    <span>
                      Liczba książek w alertach w serwisie Lubimyczytac.pl{' '}
                      <Tooltip title="Przedstawia sumę wszystkich książek we wszystkich formatach, na które ustawione są alerty w serwisie od początku uruchomienia Alertów LC">
                        <span style={{ cursor: 'pointer' }}>
                          <InfoCircleOutlined />
                        </span>
                      </Tooltip>
                    </span>
                  }
                  loading={alertsStatsLoading}
                  value={alertsStats?.books_active?.number?.total}
                  precision={2}
                  valueStyle={{ color: colors.PRIMARY }}
                  prefix={<RiseOutlined />}
                  formatter={formatter}
                />
              </CardTile>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[variables.spacing.GUTTER, variables.spacing.GUTTER]}>
        <Col xs={24}>
          <TitleSection title={`Alerty i powiadomienia ustawione na książki wydawnictwa:  "${publisher}"`} />
        </Col>
      </Row>

      <Row gutter={[variables.spacing.GUTTER, variables.spacing.GUTTER]}>
        <Col xs={24}>
          <Row gutter={[variables.spacing.GUTTER, variables.spacing.GUTTER]}>
            <Col xs={24} lg={8}>
              <CardTile>
                <Statistic
                  title={`Aktywne alerty dzisiaj na ksiazki wydawnictwa "${publisher}"`}
                  loading={alertsStatsLoading}
                  value={alertsStats?.alerts_active?.number?.by_publisher}
                  precision={2}
                  valueStyle={{ color: colors.PRIMARY }}
                  prefix={<RiseOutlined />}
                  formatter={formatter}
                />
              </CardTile>
            </Col>
            <Col xs={24} lg={8}>
              <CardTile>
                <Statistic
                  title={`Liczba wszystkich alertów na książki wydawnictwa "${publisher}"`}
                  loading={alertsStatsLoading}
                  value={alertsStats?.alerts_set?.number?.by_publisher}
                  precision={2}
                  valueStyle={{ color: colors.PRIMARY }}
                  prefix={<RiseOutlined />}
                  formatter={formatter}
                />
              </CardTile>
            </Col>
            <Col xs={24} lg={8}>
              <CardTile>
                <Statistic
                  title={`Liczba książek w alertach wydawnictwa "${publisher}"`}
                  loading={alertsStatsLoading}
                  value={alertsStats?.books_active?.number?.by_publisher}
                  precision={2}
                  valueStyle={{ color: colors.PRIMARY }}
                  prefix={<RiseOutlined />}
                  formatter={formatter}
                />
              </CardTile>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <CardTile title={`Liczba alertów wydawnictwa "${publisher}" na tle innych wydawnictw`}>
            <Column {...config} />
            <SmallNote
              note="Jeżeli nie widzisz swojego wydawnictwa w zestawieniu, oznacza to, że jest poza rankingiem. Ranking uwzględnia 50 wydawnictw cieszących się największym zainteresowaniem w serwisie. Zachęcamy do kontaktu z działem sprzedaży reklam marketing@lubimyczytac.pl. Nasi eksperci chętnie przedstwią możliwości optymalizacji statystyk poprzez skorzystanie z naszych usług
              reklamowych."
              footnote
            />
          </CardTile>
        </Col>
      </Row>

      <Row gutter={[variables.spacing.GUTTER, variables.spacing.GUTTER]} style={{ marginTop: variables.spacing.m }}>
        <Col md={24} xl={12}>
          <CardTile title={`Książki wydawnictwa "${publisher}" w aktywnych alertach`}>
            <Table
              loading={alertsStatsLoading}
              dataSource={dataTableSourceAlertsOffersSet}
              columns={columnsAlertsOffers}
              pagination={{ pageSize: 10, showSizeChanger: false, showTotal: (total, range) => `${range[0]}-${range[1]} z ${total}` }}
              scroll={{ x: 700, y: 380 }}
            />
          </CardTile>
        </Col>

        <Col md={24} xl={12}>
          <CardTile title={`Książki wydawnictwa "${publisher}" w aktywnych powiadomieniach`}>
            <Table
              loading={alertsStatsLoading}
              dataSource={dataTableSourceAlertsWithoutOffersSet}
              columns={columnsAlertsWithoutOffers}
              pagination={{ pageSize: 10, showSizeChanger: false, showTotal: (total, range) => `${range[0]}-${range[1]} z ${total}` }}
              scroll={{ x: 700, y: 380 }}
            />
          </CardTile>
        </Col>
      </Row>
    </Content>
  );
};

export default AlertsPage;
