import { Table } from 'antd';
import styled from 'styled-components';
import { colors, palette } from 'utils/colors';

const StyledTable = styled(Table)`
  .ant-table .ant-table-tbody .ant-table-row.ant-table-row--highlighted {
    background: ${palette[colors.PRIMARY][100]};
  }

  .ant-table .ant-table-tbody .ant-table-row.ant-table-row--highlighted .ant-table-cell-fix-left-last {
    background: ${palette[colors.PRIMARY][100]};
  }
`;

export default StyledTable;
