import coverImage from 'assets/images/default-book.jpg';
import { CoverImage } from './CoverStyles';

const Cover = ({ src, type, url }) => {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <CoverImage loading="lazy" src={src || coverImage} alt="Okładka książki" $small={type && type === 'small'} />
    </a>
  );
};

export default Cover;
