import styled from 'styled-components';
import { Input, Row, Col, Button } from 'antd';
import { colors } from 'utils/colors';
import { variables } from 'utils/variables';
import fonts from 'utils/fonts';

const LoginPageWrapper = styled(Row)`
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const LoginWrapper = styled(Col)`
  background-color: rgba(255, 255, 255, 0.9);
  padding: ${variables.spacing.l}px;
  box-shadow: 0 3px 32px 2px rgba(0, 0, 0, 0.15);
`;

const LoginTitle = styled(Col)`
  margin: 0 auto ${variables.spacing.s}px;
  font-family: ${fonts.lora};
  font-size: ${variables.fontSize.title.s}px;
  font-weight: 700;
  font-style: italic;
`;

const FormField = styled(Input)`
  padding: ${variables.spacing.xs}px;

  & input {
    background-color: ${colors.WHITE};
  }
`;

const FormButton = styled(Button)`
  width: 100%;
`;

const Logo = styled.img`
  display: block;
  margin: ${variables.spacing.m}px auto 0 auto;
  width: 150px;
`;

export { LoginPageWrapper, LoginWrapper, FormField, FormButton, Logo, LoginTitle };
