const formatNumberToString = (num) => {
  if (num >= 1000000) {
    return `${(num / 1000000).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })} mln`;
  } else if (num >= 1000) {
    return `${(num / 1000).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })} tys.`;
  } else {
    return num.toString();
  }
};

export default formatNumberToString;
