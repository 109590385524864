import styled from 'styled-components';
import { variables } from 'utils/variables';
import { colors } from 'utils/colors';

const AffixTopWrapper = styled.div`
  ${({ isAffixed }) =>
    isAffixed &&
    `
      background-color: ${colors.WHITE};
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: ${variables.spacing.xxs}px ${variables.spacing.GUTTER}px;

      h2 {
        margin-top: ${variables.spacing.s}px;
      }

      h2 span {
        font-size: ${variables.fontSize.body.s}px;
      }
    `}
`;

export default AffixTopWrapper;
