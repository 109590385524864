import { FooterPage } from './FooterBottomStyles';
const FooterBottom = () => {
  return (
    <FooterPage>
      <br></br>Copyright ©{' '}
      <a href="https://lubimyczytac.pl" target="_blank" rel="noreferrer">
        Lubimyczytac.pl
      </a>
    </FooterPage>
  );
};

export default FooterBottom;
