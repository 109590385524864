import { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import CountUp from 'react-countup';
import { Col, Layout, List, Row, Skeleton, Statistic } from 'antd';
import { RiseOutlined, FallOutlined } from '@ant-design/icons';
import { LoginContext } from 'pages/Router';
import { getLcStats } from 'api/services/StatsLcService';
import { getPublisherGlobalStats } from 'api/services/StatsPublisherService';
import { colors } from 'utils/colors';
import { variables } from 'utils/variables';
import formatNumberToString from 'helpers/FormatNumberToString';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import CardTile from 'components/CardTile/CardTile';
import Icon from 'components/Icon/Icon';
import Cover from 'components/Cover/Cover';
import TitleSection from 'components/TitleSection/TitleSection';

const { Content } = Layout;

const StatisticLcPage = () => {
  const { token, logout } = useContext(LoginContext);

  const [statsLc, setStatsLc] = useState({});
  const [statsLcLoading, setStatsLcLoading] = useState(true);

  const [globalStatsPublisher, setGlobalStatsPublisher] = useState({});
  const [globalStatsPublisherLoading, setGlobalStatsPublisherLoading] = useState(true);

  useEffect(() => {
    getLcStats(logout)
      .then((data) => setStatsLc(data))
      .finally(() => setStatsLcLoading(false));
  }, [logout]);

  useEffect(() => {
    getPublisherGlobalStats(token, logout)
      .then((data) => setGlobalStatsPublisher(data))
      .finally(() => setGlobalStatsPublisherLoading(false));
  }, [logout, token]);

  const breadcrumbItems = useMemo(() => ['Home', 'Statystyki LC'], []);

  const top100Books = useMemo(
    () =>
      globalStatsPublisher?.books?.top_100.map((item) => {
        return {
          title: item.book?.title ?? '',
          author: item.book?.author ?? '',
          cover: item.book?.cover ?? '',
          url: item.book?.url ?? '',
          icon: parseInt(item.book?.rank?.rank1) > parseInt(item.book?.rank?.rank2) ? <RiseOutlined style={{ color: colors.POSITIVE }} /> : <FallOutlined style={{ color: colors.NEGATIVE }} />,
        };
      }),
    [globalStatsPublisher],
  );

  const formatter = useCallback((value) => <CountUp end={value} separator=" " formattingFn={formatNumberToString} />, []);

  return (
    <Content>
      <Breadcrumbs items={breadcrumbItems} />
      <Row gutter={[variables.spacing.GUTTER, variables.spacing.GUTTER]}>
        <Col xs={24}>
          <TitleSection headerText="Lubimyczytać w liczbach" />

          <Row gutter={[variables.spacing.GUTTER, variables.spacing.GUTTER]} type="flex" align="stretch">
            <Col xs={24} sm={12} xl={6}>
              <CardTile>
                <Statistic
                  title="Użytkowników serwisu"
                  loading={statsLcLoading}
                  value={statsLc?.users_amount}
                  precision={2}
                  valueStyle={{ color: colors.PRIMARY }}
                  prefix={<Icon icon="user" size={19} color={colors.PRIMARY} />}
                  suffix="+"
                  formatter={formatter}
                />
              </CardTile>
            </Col>
            <Col xs={24} sm={12} xl={6}>
              <CardTile>
                <Statistic
                  title="Ocen wystawionych książkom"
                  loading={statsLcLoading}
                  value={statsLc?.ratings_amount}
                  precision={2}
                  valueStyle={{ color: colors.PRIMARY }}
                  prefix={<Icon icon="thumbs-up" size={19} color={colors.PRIMARY} />}
                  suffix="+"
                  formatter={formatter}
                />
              </CardTile>
            </Col>
            <Col xs={24} sm={12} xl={6}>
              <CardTile>
                <Statistic
                  title="Dodanych opinii"
                  loading={statsLcLoading}
                  value={statsLc?.reviews_amount}
                  precision={2}
                  valueStyle={{ color: colors.PRIMARY }}
                  prefix={<Icon icon="comment" size={19} color={colors.PRIMARY} />}
                  suffix="+"
                  formatter={formatter}
                />
              </CardTile>
            </Col>
            <Col xs={24} sm={12} xl={6}>
              <CardTile>
                <Statistic
                  title="Użytkowników serwisu w ciągu ostatnich 30 dni"
                  loading={statsLcLoading}
                  value={statsLc?.page_views?.last_month}
                  precision={2}
                  valueStyle={{ color: colors.PRIMARY }}
                  prefix={<Icon icon="fans" size={19} color={colors.PRIMARY} />}
                  suffix="+"
                  formatter={formatter}
                />
              </CardTile>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <CardTile title="TOP 100 książek w Lubimyczytać.pl" loading={globalStatsPublisherLoading}>
            <List
              itemLayout="horizontal"
              pagination={{ pageSize: 10, showSizeChanger: false, showTotal: (total, range) => `${range[0]}-${range[1]} z ${total}` }}
              dataSource={top100Books}
              renderItem={(item) => (
                <List.Item>
                  <Skeleton avatar title={false} loading={item.loading} active>
                    <List.Item.Meta
                      avatar={<Cover src={item.cover} type="small" url={item.url} />}
                      title={<span className="singleLine">{item.title}</span>}
                      description={<span className="singleLine">{item.author}</span>}
                    />
                    <div style={{ marginLeft: variables.spacing.xs, textAlign: 'right' }}>{item.icon}</div>
                  </Skeleton>
                </List.Item>
              )}
            />
          </CardTile>
        </Col>
      </Row>
    </Content>
  );
};

export default StatisticLcPage;
