export const logIn = async (values) => {
  try {
    const response = await fetch('https://lubimyczytac.pl/publisher/api/login', {
      method: 'POST',
      mode: 'cors',
      body: `login=${values.login}&password=${values.password}`,
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error('Błąd logowania!');
    }
  } catch (error) {
    console.error('Wystąpił błąd podczas logowania:', error);
  }
};

export const logOut = async (token) => {
  try {
    await fetch('https://lubimyczytac.pl/publisher/api/logout', {
      method: 'POST',
      mode: 'cors',
      body: `token=${token}`,
    });
  } catch (error) {
    console.error('Wystąpił błąd podczas wylogowania:', error);
  }
};
