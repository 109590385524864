import { Icon, Note } from './SmallNoteStyles';

const SmallNote = ({ note, footnote }) => (
  <Note $footnote={footnote}>
    {!footnote && <Icon />}
    {note}
  </Note>
);

export default SmallNote;
