import { Row, Col, Segmented, Typography } from 'antd';
import StyledTitle from './TitleSectionStyles';

const { Text } = Typography;

const TitleSection = ({ title, segmentedOptions, onSegmentedChange, defaultValue }) => {
  return (
    <Row gutter={16} type="flex" align="middle">
      <Col>
        <StyledTitle>
          <Text
            ellipsis={{
              symbol: 'more',
            }}
          >
            {title}
          </Text>
        </StyledTitle>
      </Col>
      {segmentedOptions && (
        <Col xs={24} sm={12}>
          <Segmented block size="small" options={segmentedOptions} onChange={onSegmentedChange} defaultValue={defaultValue} />
        </Col>
      )}
    </Row>
  );
};

export default TitleSection;
