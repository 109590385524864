import { Bar } from '@ant-design/plots';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import CardTile from '../CardTile/CardTile';
import { RankInfo, TooltipTrigger } from './RankBarChartStyles';

const RankBarChart = ({ statsLoading, charTitle, rank, configRating, publisher }) => {
  const isInRank = rank > 0;

  return (
    <CardTile loading={statsLoading} title={charTitle}>
      <RankInfo>
        {`Miejsce wydawnictwa "${publisher}" w rankingu: ${isInRank ? rank : `poza rankingiem `}`}
        {!isInRank && (
          <Tooltip title="Ranking uwzględnia 50 wydawnictw cieszących się największym zainteresowaniem w serwisie Lubimyczytać.pl. Zachęcamy do kontaktu z działem sprzedaży reklam marketing@lubimyczytac.pl. Nasi eksperci chętnie przedstwią możliwości optymalizacji statystyk poprzez skorzystanie z naszych usług reklamowych.">
            <TooltipTrigger>
              <InfoCircleOutlined />
            </TooltipTrigger>
          </Tooltip>
        )}
      </RankInfo>
      <Bar {...configRating} />
    </CardTile>
  );
};

export default RankBarChart;
