import { Card } from 'antd';
import { CardSmallInfo } from './CardTileStyles';

const CardTile = ({ children, title = null, text = null, size = null, loading = false, onClick = null }) => {
  return (
    <Card loading={loading} size={size} title={title} onClick={onClick} bordered={false} style={{ height: '100%', cursor: onClick ? 'pointer' : 'auto' }}>
      <CardSmallInfo>{text}</CardSmallInfo>
      {children}
    </Card>
  );
};

export default CardTile;
