import { useState } from 'react';
import { Layout } from 'antd';
import MainMenu from 'components/MainMenu/MainMenu';
import SidebarWrapper from './SidebarStyles.js';

const { Sider } = Layout;

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <SidebarWrapper>
      <Sider width={220} style={{ height: '100%' }} collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <MainMenu />
      </Sider>
    </SidebarWrapper>
  );
};

export default Sidebar;
