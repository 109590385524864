import React, { useCallback, useMemo, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LayoutPage from './Layout/LayoutPage';
import LoginPage from './Login/LoginPage';
import DashboardPage from './Dashboard/DashboardPage';
import ComparisonDashboardPage from './ComparisonDashboard/ComparisonDashboardPage';
import AlertsPage from './Alerts/AlertsPage';
import StatisticLcPage from './StatisticLc/StatisticLcPage';

export const LoginContext = React.createContext(null);

const Router = () => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [username, setUsername] = useState(localStorage.getItem('username'));
  const [publisher, setPublisher] = useState(localStorage.getItem('publisher'));
  const [publisherUrl, setPublisherUrl] = useState(localStorage.getItem('publisherUrl'));
  const [accessLevel, setAccessLevel] = useState(localStorage.getItem('accessLevel'));

  const manageLocalStorage = (name, value) => {
    if (value) {
      localStorage.setItem(name, value);
    } else {
      localStorage.removeItem(name);
    }
  };

  const manageToken = useCallback((token) => {
    manageLocalStorage('token', token);
    setToken(token);
  }, []);

  const manageUserInfo = useCallback((username, publisher, accessLevel, publisherUrl) => {
    manageLocalStorage('username', username);
    manageLocalStorage('publisher', publisher);
    manageLocalStorage('accessLevel', accessLevel);
    manageLocalStorage('publisherUrl', publisherUrl);
    setUsername(username);
    setPublisher(publisher);
    setAccessLevel(accessLevel);
    setPublisherUrl(publisherUrl);
  }, []);

  const logout = useCallback(() => {
    manageToken(null);
    manageUserInfo(null, null, null, null);
  }, [manageToken, manageUserInfo]);

  const contextValue = useMemo(() => {
    return {
      token,
      setToken: manageToken,
      username,
      publisher,
      accessLevel,
      publisherUrl,
      manageUserInfo,
      logout,
    };
  }, [token, manageToken, username, publisher, accessLevel, publisherUrl, manageUserInfo, logout]);

  return (
    <BrowserRouter>
      <LoginContext.Provider value={contextValue}>
        <Routes>
          <Route path="/" element={token ? <LayoutPage /> : <LoginPage />}>
            <Route index element={<Navigate to="dla-wydawcy/moje-statystyki" />} />
            <Route path="alerty" element={<AlertsPage />} />
            <Route path="dla-wydawcy/moje-statystyki" element={<DashboardPage />} />
            <Route path="dla-wydawcy/porownaj-statystyki" element={<ComparisonDashboardPage />} />
            <Route path="statystyki-lc" element={<StatisticLcPage />} />
          </Route>
          <Route path="/zaloguj" element={<LoginPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </LoginContext.Provider>
    </BrowserRouter>
  );
};

export default Router;
