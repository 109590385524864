export const dateRanges = {
  MONTH: 'miesiąc',
  QUARTER: 'kwartał',
  HALF_YEAR: 'pół roku',
  YEAR: 'rok',
  FULL: 'pełen zakres',
};

const dateRangeValues = {};

dateRangeValues[dateRanges.MONTH] = 30;
dateRangeValues[dateRanges.QUARTER] = 90;
dateRangeValues[dateRanges.HALF_YEAR] = 180;
dateRangeValues[dateRanges.YEAR] = 365;
dateRangeValues[dateRanges.FULL] = 10950;

export { dateRangeValues };
