import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { HomeOutlined, LineChartOutlined } from '@ant-design/icons';
import { colors } from '../../utils/colors';
import Icon from '../../components/Icon/Icon';

const MainMenu = () => {
  const [openKeys, setOpenKeys] = useState(['sub1']);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey) {
      setOpenKeys([latestOpenKey]);
    } else {
      setOpenKeys([]);
    }
  };

  const getItem = (label, key, icon, children) => {
    return {
      key,
      icon,
      children,
      label,
    };
  };

  const items = [
    getItem('Dla wydawcy', 'sub1', <HomeOutlined />, [
      getItem(<Link to="/dla-wydawcy/moje-statystyki">Moje statystyki</Link>, '1'),
      getItem(<Link to="/dla-wydawcy/porownaj-statystyki">Porównaj statystyki</Link>, '2'),
    ]),

    getItem('Alerty LC', 'sub2', <Icon style={{ marginTop: '-5px' }} icon="alert" size={18} color={colors.PRIMARY} />, [getItem(<Link to="/alerty">Alerty</Link>, '3')]),
    getItem(<Link to="/statystyki-lc">Statystyki LC</Link>, '4', <LineChartOutlined />),
  ];

  return <Menu mode="inline" defaultSelectedKeys={['1']} openKeys={openKeys} items={items} onOpenChange={onOpenChange} style={{ height: '100%' }} />;
};

export default MainMenu;
