import { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import CountUp from 'react-countup';
import { Button, Col, Layout, List, Row, Skeleton, Statistic, Table, Tag, Tooltip } from 'antd';
import { Line } from '@ant-design/plots';
import { LikeOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { LoginContext } from 'pages/Router';
import { getPublisherStats, getExternalPublisherStats } from 'api/services/StatsPublisherService';
import { variables } from 'utils/variables';
import { colors } from 'utils/colors';
import { dateRanges, dateRangeValues } from 'utils/dateRanges';
import formatNumberToString from 'helpers/FormatNumberToString';
import useColumnSearchProps from 'helpers/CustomTableSorter';
import AffixTop from 'components/AffixTop/AffixTop';
import CardTile from 'components/CardTile/CardTile';
import CardTileWithModal from 'components/CardTitleWithModal/CardTileWithModal';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import TooltipContent from 'components/TooltipContent/TooltipContent';
import TitleSection from 'components/TitleSection/TitleSection';
import Icon from 'components/Icon/Icon';
import ChartButton from 'components/ChartButton/ChartButton';
import SmallNote from 'components/SmallNote/SmallNote';
import TimeFrameTag from 'components/TimeFrameTag/TimeFrameTag';
import Cover from 'components/Cover/Cover';

import { UserName, TooltipTrigger, IntroInfo, CounterContainer, CounterDescription, DashboardCta, StyledStar } from './DashboardPageStyles';

const { Content } = Layout;

const DashboardPage = () => {
  const { username, token, logout, publisher, accessLevel, publisherUrl } = useContext(LoginContext);

  const [statsPublisher, setStatsPublisher] = useState({});
  const [statsPublisherLoading, setStatsPublisherLoading] = useState(true);

  const [externalStatsPublisher, setExternalStatsPublisher] = useState({});
  const [externalStatsPublisherLoading, setExternalStatsPublisherLoading] = useState(true);

  const [timeFrame, setTimeFrame] = useState(dateRanges.MONTH);

  useEffect(() => {
    setStatsPublisherLoading(true);
    getPublisherStats(token, logout, dateRangeValues[timeFrame])
      .then((data) => {
        setStatsPublisher(data);
      })
      .finally(() => {
        setStatsPublisherLoading(false);
      });
  }, [logout, timeFrame, token]);

  useEffect(() => {
    setExternalStatsPublisherLoading(true);
    getExternalPublisherStats(token, logout, dateRangeValues[timeFrame])
      .then((data) => {
        setExternalStatsPublisher(data);
      })
      .finally(() => {
        setExternalStatsPublisherLoading(false);
      });
  }, [logout, timeFrame, token]);

  const formatter = useCallback((value) => <CountUp end={value} separator=" " formattingFn={formatNumberToString} />, []);

  // Opinie - top 10
  const topTenBoksReviewsByTotal = useMemo(() => {
    const topByReviewsAmount = (statsPublisher?.books?.top_by_reviews_total?.books || []).slice(0, 10);
    return topByReviewsAmount.map((item) => ({
      title: item.book.title ?? '',
      author: item.book.author ?? '',
      cover: item.book.cover ?? '',
      url: item.book.url ?? '',
      counter: `${item.book.reviews_amount ?? '-'}`,
    }));
  }, [statsPublisher]);

  // Oceny - top 10
  const topTenBoksRatingByTotal = useMemo(() => {
    const topByRaitingAmount = (statsPublisher?.books?.top_by_rating_total?.books || []).slice(0, 10);
    return topByRaitingAmount.map((item) => ({
      title: item.book.title ?? '',
      author: item.book.author ?? '',
      cover: item.book.cover ?? '',
      url: item.book.url ?? '',
      counter: `${item.book.rating_votes ?? 'brak'}`,
    }));
  }, [statsPublisher]);

  // Opinie - top 10
  const topTenBoksReviews = useMemo(() => {
    const topByReviewsAmount = (statsPublisher?.books?.top_by_reviews_amount?.books || []).slice(0, 10);
    return topByReviewsAmount.map((item) => ({
      title: item.book.title ?? '',
      author: item.book.author ?? '',
      cover: item.book.cover ?? '',
      url: item.book.url ?? '',
      counter: `${item.book.reviews_amount ?? '-'}`,
    }));
  }, [statsPublisher]);

  // Oceny - top 10
  const topTenBoksRating = useMemo(() => {
    const topByRaitingAmount = (statsPublisher?.books?.top_by_rating_amount?.books || []).slice(0, 10);
    return topByRaitingAmount.map((item) => ({
      title: item.book.title ?? '',
      author: item.book.author ?? '',
      cover: item.book.cover ?? '',
      url: item.book.url ?? '',
      counter: `${item.book.rating_votes ?? 'brak'}`,
    }));
  }, [statsPublisher]);

  // Tabela - wyświetlenia stron książek

  const { getColumnSearchProps } = useColumnSearchProps();

  let dataExternalTableSource = externalStatsPublisher?.books?.page_views?.rank ?? [];

  if (dataExternalTableSource) {
    dataExternalTableSource = dataExternalTableSource.map((item, index) => ({
      key: `column${index}`,
      column1: <Cover src={item.cover} url={item.url} />,
      column2: (
        <a href={item.url} target="_blank" rel="noreferrer">
          {item.title}
        </a>
      ),
      column3: item.page_views,
      column4: <ChartButton bookId={item.id} modalTitle={item.title} timeFrame={timeFrame} />,
    }));
  }

  const columns = [
    {
      title: '',
      dataIndex: 'column1',
      key: 'column1',
      width: 120,
    },
    {
      title: 'Tytuł książki',
      dataIndex: 'column2',
      key: 'column2',
      width: 580,
      ...getColumnSearchProps('column2'),
      sorter: (a, b) => {
        const titleA = a.column2.props ? a.column2.props.children : a.column2;
        const titleB = b.column2.props ? b.column2.props.children : b.column2;
        return titleA.localeCompare(titleB);
      },
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Liczba wyświetleń strony',
      dataIndex: 'column3',
      key: 'column3',
      width: 240,
      sorter: (a, b) => a.column3 - b.column3,
    },
    {
      title: 'Szczegóły',
      dataIndex: 'column4',
      key: 'column4',
      width: 240,
    },
  ];

  // Wykres - liczba unikalnych użytkowników
  const externalPublisherDataChart = useMemo(() => {
    const timeline = externalStatsPublisher?.books?.page_views?.timeline;
    let data = [];
    if (timeline) {
      data = timeline.map((pageView) => {
        return {
          time: `${pageView?.day ? pageView.day + '.' : ''}${pageView?.month ? pageView.month + '.' : ''}${pageView?.year}`,
          'unikalni użytkownicy': parseInt(pageView?.page_views),
        };
      });
    }
    return data;
  }, [externalStatsPublisher?.books?.page_views?.timeline]);

  const lineChart = useMemo(() => {
    const config = {
      data: externalPublisherDataChart,
      xField: 'time',
      yField: 'unikalni użytkownicy',
      label: {},
      point: {
        size: 5,
        shape: 'circle',
        style: {
          fill: 'white',
          stroke: colors.PRIMARY,
          lineWidth: 2,
        },
      },
      tooltip: {
        showMarkers: false,
        customContent: (title, items) => {
          return <TooltipContent title={title} items={items} viewsLabel={'Liczba unikalnych użytkowników'}></TooltipContent>;
        },
      },
      state: {
        active: {
          style: {
            shadowColor: colors.PRIMARY,
            shadowBlur: 4,
            stroke: 'transparent',
            fill: colors.PRIMARY,
          },
        },
      },
      lineStyle: {
        stroke: colors.PRIMARY,
        lineWidth: 4,
        lineJoin: 'round',
        lineCap: 'round',
      },
      theme: {
        geometries: {
          point: {
            diamond: {
              active: {
                style: {
                  shadowColor: colors.PRIMARY,
                  shadowBlur: 2,
                  stroke: colors.PRIMARY,
                },
              },
            },
          },
        },
      },
      interactions: [{ type: 'marker-active' }],
    };
    return <Line {...config} />;
  }, [externalPublisherDataChart]);

  const handleTimeFrameChange = (value) => {
    setTimeFrame(value);
  };

  const breadcrumbItems = useMemo(() => ['Home', 'Dla wydawców', 'Panel wydawców'], []);

  const dataRangePanel = 'dane zbierane od momentu uruchomienia panelu dla wydawnictwa';
  const dataRangeLC = 'dane od momentu dodania pozycji do serwisu Lubimyczytac.pl';

  const recentBookExplanation =
    'Sekcja prezentuje ostatnich 200 książek dodanych do serwisu Lubimyczytac.pl. Książki w serwisie Lubimyczytac.pl muszą być w pełni opublikowane, co oznacza, że muszą posiadać okładki oraz nie mogą być w trakcie procesu moderacji. Proces moderacji powoduje wstrzymanie promocji książki w wyszukiwarce Lubimyczytac.pl oraz na listach promocyjnych.';

  return (
    <Content>
      <Breadcrumbs items={breadcrumbItems} />
      <AffixTop>
        <TitleSection
          title={`Wydawnictwo "${publisher}" w serwisie Lubimyczytac.pl`}
          segmentedOptions={[dateRanges.FULL, dateRanges.MONTH, dateRanges.QUARTER, dateRanges.YEAR]}
          onSegmentedChange={handleTimeFrameChange}
          defaultValue={timeFrame}
        />
      </AffixTop>

      <Row gutter={[variables.spacing.GUTTER, variables.spacing.GUTTER]}>
        <Col xs={24}>
          <CardTile>
            Cześć, <UserName>{username}</UserName>
            <IntroInfo>Wydawnictwo: {publisher}</IntroInfo>
            <Tag style={{ display: 'inline-block', marginTop: variables.spacing.xs }}>{accessLevel === '1' ? 'konto podstawowe' : 'konto premium'}</Tag>
            <IntroInfo>
              Rozważ możliwość dalszej optymalizacji swoich statystyk poprzez skorzystanie z naszych usług reklamowych. Zachęcamy do kontaktu z naszym działem sprzedaży reklam, gdzie nasi eksperci
              chętnie omówią z Tobą dostępne opcje. <br></br>Napisz do nas <a href="mailto:marketing@lubimyczytac.pl">marketing@lubimyczytac.pl</a>.
              <a href={publisherUrl} target="_blank" rel="noreferrer">
                <Button style={{ display: 'block', marginTop: variables.spacing.s }} type="primary">
                  Strona wydawnictwa
                </Button>
              </a>
            </IntroInfo>
          </CardTile>
        </Col>

        <Col xs={24} style={{ marginBottom: -variables.spacing.GUTTER }}>
          <TitleSection title="Popularność wśród internautów" />
        </Col>

        <Col xs={24}>
          <CardTile
            title={
              <>
                Rozkład ruchu użytkowników na książkach <TimeFrameTag timeFrame={timeFrame} />
              </>
            }
            text={`Liczba odsłon stron książek wydawnictwa "${publisher}"`}
            loading={externalStatsPublisherLoading}
          >
            <SmallNote note={dataRangePanel} />
            <div>{externalPublisherDataChart.length === 0 ? 'Brak Danych' : lineChart}</div>
          </CardTile>
        </Col>

        <Col xs={24}>
          <CardTile
            title={
              <>
                Rozkład ruchu na książkach <TimeFrameTag timeFrame={timeFrame} />
              </>
            }
            loading={externalStatsPublisherLoading}
            text={
              <>
                Wyświetlenia stron książek wydawnictwa "{publisher}"
                <Tooltip title={recentBookExplanation}>
                  <TooltipTrigger>
                    <InfoCircleOutlined />
                  </TooltipTrigger>
                </Tooltip>
              </>
            }
          >
            <SmallNote note={dataRangePanel} />
            <Table
              loading={externalStatsPublisherLoading}
              dataSource={dataExternalTableSource}
              columns={columns}
              pagination={{ pageSize: 8, showSizeChanger: false, showTotal: (total, range) => `${range[0]}-${range[1]} z ${total}` }}
              scroll={{ x: 700, y: 620 }}
            />
          </CardTile>
        </Col>

        <Col xs={24}>
          <TitleSection title="Popularność wśród czytelników" />
          <Row gutter={[variables.spacing.GUTTER, variables.spacing.GUTTER]}>
            <Col xs={24}>
              <Row gutter={[variables.spacing.GUTTER, variables.spacing.GUTTER]} type="flex" align="stretch">
                <Col xs={24} sm={12} xl={8}>
                  <CardTileWithModal shelfNumber="1" daysBack={dateRangeValues[timeFrame]} modalTitle="Książki na półce: Przeczytane" timeFrame={timeFrame}>
                    <>
                      <Statistic
                        title={
                          <>
                            Liczba czytelników, którzy mają książki wydawnictwa "{publisher}" oznaczone jako <br></br>
                            <b>“Przeczytane”</b> <TimeFrameTag timeFrame={timeFrame} />
                            <br></br>
                          </>
                        }
                        loading={statsPublisherLoading}
                        value={statsPublisher?.reader_amount?.by_shelfs?.read}
                        precision={2}
                        valueStyle={{ color: colors.PRIMARY }}
                        prefix={<Icon icon="read" size={19} color={colors.PRIMARY} />}
                        formatter={formatter}
                      />
                      <DashboardCta type="default">Zobacz książki</DashboardCta>
                    </>
                    <SmallNote note={dataRangeLC} />
                  </CardTileWithModal>
                </Col>
                <Col xs={24} sm={12} xl={8}>
                  <CardTileWithModal shelfNumber="3" daysBack={dateRangeValues[timeFrame]} modalTitle="Książki na półce: Chcę przeczytać" timeFrame={timeFrame}>
                    <>
                      <Statistic
                        title={
                          <>
                            Liczba czytelników, którzy mają książki wydawnictwa "{publisher}" oznaczone jako <br></br>
                            <b>“Chcę przeczytać”</b> <TimeFrameTag timeFrame={timeFrame} />
                          </>
                        }
                        loading={statsPublisherLoading}
                        value={statsPublisher?.reader_amount?.by_shelfs?.wants_to_read}
                        precision={2}
                        valueStyle={{ color: colors.PRIMARY }}
                        prefix={<Icon icon="target" size={20} color={colors.PRIMARY} />}
                        formatter={formatter}
                      />
                      <DashboardCta type="default">Zobacz książki</DashboardCta>
                    </>
                    <SmallNote note={dataRangeLC} />
                  </CardTileWithModal>
                </Col>
                <Col xs={24} sm={12} xl={8}>
                  <CardTileWithModal shelfNumber="2" daysBack={dateRangeValues[timeFrame]} modalTitle="Książki na półce: Teraz czytam" timeFrame={timeFrame}>
                    <>
                      <Statistic
                        title={
                          <>
                            Liczba czytelników, którzy mają ksiązki wydawnictwa "{publisher}" oznaczone jako <br></br> <b>“Teraz czytam"</b> <TimeFrameTag timeFrame={timeFrame} />
                          </>
                        }
                        loading={statsPublisherLoading}
                        value={statsPublisher?.reader_amount?.by_shelfs?.reading}
                        precision={2}
                        valueStyle={{ color: colors.PRIMARY }}
                        prefix={<Icon icon="book" size={20} style={{ height: '20px' }} color={colors.PRIMARY} />}
                        formatter={formatter}
                      />
                      <DashboardCta type="default">Zobacz książki</DashboardCta>
                    </>
                    <SmallNote note={dataRangeLC} />
                  </CardTileWithModal>
                </Col>
                <Col xs={24} sm={12}>
                  <CardTile>
                    <Statistic
                      title={
                        <>
                          Suma ocen wystawionych książkom wydawnictwa "{publisher}" <TimeFrameTag timeFrame={timeFrame} />
                        </>
                      }
                      loading={statsPublisherLoading}
                      value={statsPublisher?.rating?.total}
                      precision={2}
                      valueStyle={{ color: colors.PRIMARY }}
                      prefix={<Icon icon="thumbs-up" size={19} color={colors.PRIMARY} />}
                      formatter={formatter}
                    />
                    <SmallNote note={dataRangeLC} />
                  </CardTile>
                </Col>
                <Col xs={24} sm={12}>
                  <CardTile>
                    <Statistic
                      title={
                        <>
                          Liczba opinii dodanych do książek wydawnictwa <TimeFrameTag timeFrame={timeFrame} />
                        </>
                      }
                      loading={statsPublisherLoading}
                      value={statsPublisher?.review?.total}
                      precision={2}
                      valueStyle={{ color: colors.PRIMARY }}
                      prefix={<Icon icon="comment" size={19} color={colors.PRIMARY} />}
                      formatter={formatter}
                    />
                    <SmallNote note={dataRangeLC} />
                  </CardTile>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <TitleSection title="Popularność wśród społeczności" />
          <Row gutter={[variables.spacing.GUTTER, variables.spacing.GUTTER]}>
            <Col xs={24} lg={12}>
              <CardTile
                title={
                  <>
                    TOP 10 książek z największą jednostkową liczbą ocen <TimeFrameTag timeFrame={timeFrame} />
                  </>
                }
                text="Poznaj rozkład liczby ocen dotyczących poszczególnych wydań książek. Liczba ocen dotyczy pojedynczego wydania książki i nie wliczają się w nią oceny książek będących kolejnymi lub innymi wydaniami."
                loading={statsPublisherLoading}
              >
                <SmallNote note="dane od momentu pozycji dodania do serwisu Lubimyczytac.pl" />
                <List
                  itemLayout="horizontal"
                  dataSource={topTenBoksRating}
                  renderItem={(item) => (
                    <List.Item>
                      <Skeleton avatar title={false} loading={item.loading} active>
                        <List.Item.Meta
                          avatar={<Cover src={item.cover} type="small" url={item.url} />}
                          title={
                            <a className="singleLine" href={item.url} target="_blank" rel="noreferrer">
                              {item.title}
                            </a>
                          }
                          description={<span className="singleLine">{item.author}</span>}
                        />
                        <CounterContainer>
                          <StyledStar />
                          {item.counter} <CounterDescription>ocen</CounterDescription>
                        </CounterContainer>
                      </Skeleton>
                    </List.Item>
                  )}
                />
              </CardTile>
            </Col>

            <Col xs={24} lg={12}>
              <CardTile
                title={
                  <>
                    TOP 10 książek z największą jednostkową liczbą opinii <TimeFrameTag timeFrame={timeFrame} />
                  </>
                }
                text="Poznaj rozkład liczby opinii dotyczących poszczególnych wydań książek. Liczba opinii dotyczy pojedynczego wydania książki i nie wliczają się w nią opinie książek będących kolejnymi lub innymi wydaniami."
                loading={statsPublisherLoading}
              >
                <SmallNote note={dataRangeLC} />
                <List
                  itemLayout="horizontal"
                  dataSource={topTenBoksReviews}
                  renderItem={(item) => (
                    <List.Item>
                      {/* ! TO DO - loading */}
                      <Skeleton avatar title={false} loading={item.loading} active>
                        <List.Item.Meta
                          avatar={<Cover src={item.cover} type="small" url={item.url} />}
                          title={
                            <a className="singleLine" href={item.url} target="_blank" rel="noreferrer">
                              {item.title}
                            </a>
                          }
                          description={<span className="singleLine">{item.author}</span>}
                        />
                        <CounterContainer>
                          <LikeOutlined style={{ marginRight: variables.spacing.xxs }} />
                          {item.counter} <CounterDescription>opinii</CounterDescription>
                        </CounterContainer>
                      </Skeleton>
                    </List.Item>
                  )}
                />
              </CardTile>
            </Col>
          </Row>
        </Col>

        <Col xs={24} lg={12}>
          <CardTile
            title={
              <>
                TOP 10 książek z największą sumaryczną liczbą ocen <TimeFrameTag timeFrame="pełen zakres" />
              </>
            }
            text="Poznaj rozkład ocen wszystkich wydań danej książki. Liczba ocen jest tutaj sumą ocen wszystkich wydań książki, innych lub kolejnych."
            loading={statsPublisherLoading}
          >
            <SmallNote note={dataRangeLC} />
            <List
              itemLayout="horizontal"
              dataSource={topTenBoksRatingByTotal}
              renderItem={(item) => (
                <List.Item>
                  <Skeleton avatar title={false} loading={item.loading} active>
                    <List.Item.Meta
                      avatar={<Cover src={item.cover} type="small" url={item.url} />}
                      title={
                        <a className="singleLine" href={item.url} target="_blank" rel="noreferrer">
                          {item.title}
                        </a>
                      }
                      description={<span className="singleLine">{item.author}</span>}
                    />
                    <CounterContainer>
                      <StyledStar />
                      {item.counter} <CounterDescription>ocen</CounterDescription>
                    </CounterContainer>
                  </Skeleton>
                </List.Item>
              )}
            />
          </CardTile>
        </Col>

        <Col xs={24} lg={12}>
          <CardTile
            title={
              <>
                TOP 10 książek z największą sumaryczną liczbą opinii <TimeFrameTag timeFrame="pełen zakres" />
              </>
            }
            text="Poznaj rozkład opinii dla wszystkich wydań danej książki. Liczba opinii jest tutaj sumą opinii wystawionych dla wszystkich wydań książki, innych lub kolejnych."
            loading={statsPublisherLoading}
          >
            <SmallNote note={dataRangeLC} />
            <List
              itemLayout="horizontal"
              dataSource={topTenBoksReviewsByTotal}
              renderItem={(item) => (
                <List.Item>
                  {/* ! TO DO - loading */}
                  <Skeleton avatar title={false} loading={item.loading} active>
                    <List.Item.Meta
                      avatar={<Cover src={item.cover} type="small" url={item.url} />}
                      title={
                        <a className="singleLine" href={item.url} target="_blank" rel="noreferrer">
                          {item.title}
                        </a>
                      }
                      description={<span className="singleLine">{item.author}</span>}
                    />
                    <CounterContainer>
                      <LikeOutlined style={{ marginRight: variables.spacing.xxs }} />
                      {item.counter} <CounterDescription>opinii</CounterDescription>
                    </CounterContainer>
                  </Skeleton>
                </List.Item>
              )}
            />
          </CardTile>
        </Col>
      </Row>
    </Content>
  );
};

export default DashboardPage;
