import { useState } from 'react';
import { Affix } from 'antd';
import AffixTopWrapper from './AffixTopStyles';

const AffixTop = ({ children }) => {
  const [isAffixed, setIsAffixed] = useState(false);

  const handleAffixChange = (affixed) => {
    setIsAffixed(affixed);
  };

  return (
    <Affix offsetTop={55} onChange={handleAffixChange}>
      <AffixTopWrapper isAffixed={isAffixed}>{children}</AffixTopWrapper>
    </Affix>
  );
};

export default AffixTop;
