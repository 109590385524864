import { useState, useContext } from 'react';
import { Avatar, Dropdown, Drawer, Button } from 'antd';
import { UserOutlined, DownOutlined, UpOutlined, MenuOutlined } from '@ant-design/icons';
import { logOut } from 'api/services/LoginService';
import MainMenu from 'components/MainMenu/MainMenu';
import { LoginContext } from '../../pages/Router';
import { variables } from '../../utils/variables';
import logo from '../../assets/images/lc-logo-panel.svg';
import { HeaderContainer, LogoImage, HeaderMenu, UserMenuName } from './HeaderTopStyles';

const HeaderTop = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { logout, username, token } = useContext(LoginContext);

  const onLogout = () => {
    logOut(token);
    logout();
  };

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const items = [
    {
      key: '1',
      label: (
        <span>
          Moje konto <small>(wkrótce)</small>
        </span>
      ),
    },
    {
      key: '2',
      label: <span onClick={onLogout}>Wyloguj</span>,
    },
  ];

  return (
    <HeaderContainer>
      <LogoImage src={logo} alt="Logo Lubimyczytac.pl Panel" />
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
        }}
      >
        <Dropdown
          menu={{
            items,
          }}
          placement="bottom"
          onOpenChange={(visible) => setDropdownOpen(visible)}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              marginLeft: `${variables.spacing.xs}px`,
            }}
          >
            <Avatar size={{ xs: 34 }} icon={<UserOutlined />} />
            <UserMenuName>{username}</UserMenuName>
            <span className="user-menu__arrow" style={{ marginLeft: variables.spacing.xs, fontSize: '10px' }}>
              {dropdownOpen ? <UpOutlined /> : <DownOutlined />}
            </span>
          </div>
        </Dropdown>
        <HeaderMenu>
          <Button type="text" onClick={showDrawer} icon={<MenuOutlined />}></Button>
          <Drawer title="Menu" placement="right" onClose={onClose} open={visible}>
            <MainMenu />
          </Drawer>
        </HeaderMenu>
      </div>
    </HeaderContainer>
  );
};

export default HeaderTop;
