export const variables = {
  spacing: {
    GUTTER: 16,
    GUTTER_DESKTOP: 24,
    xxs: 4,
    xs: 8,
    s: 16,
    m: 24,
    l: 32,
    xl: 40,
  },
  fontSize: {
    title: {
      xl: 64,
      l: 44,
      m: 32,
      s: 24,
      xs: 20,
    },
    body: {
      default: 16,
      s: 14,
      xs: 12,
      xxs: 10,
    },
  },
  zIndex: {
    base: 0,
    above: 1,
    below: -1,
    header: 13,
  },
  durationMedium: '0.3s',
};
