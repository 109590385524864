import styled from 'styled-components';
import { Tag } from 'antd';
import { colors, palette } from 'utils/colors';

export const TimeFrameTagAccent = styled(Tag)`
  font-weight: 400;
  color: ${colors.PRIMARY};
  background-color: ${palette[colors.PRIMARY][100]};
  opacity: 0.8;
  border: none;
`;
