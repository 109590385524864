import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { Modal, Table, Space } from 'antd';
import { Line } from '@ant-design/plots';
import { getPublisherShelfStats } from 'api/services/StatsPublisherService';
import CardTitle from 'components/CardTile/CardTile';
import { LoginContext } from 'pages/Router';
import { colors } from 'utils/colors';
import TooltipContent from 'components/TooltipContent/TooltipContent';
import SmallNote from 'components/SmallNote/SmallNote';
import TimeFrameTag from 'components/TimeFrameTag/TimeFrameTag';
import Cover from 'components/Cover/Cover';
import useColumnSearchProps from 'helpers/CustomTableSorter';

const CardTileWithModal = ({ shelfNumber, daysBack, children, modalTitle, timeFrame, title = null, size = null, loading = false, hoverable = false }) => {
  const { token, logout } = useContext(LoginContext);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(null);
  const shelfName = parseInt(shelfNumber) === 1 ? 'reading' : parseInt(shelfNumber) === 2 ? 'wants_to_read' : 'read';
  const { getColumnSearchProps } = useColumnSearchProps();
  const tableData = useMemo(
    () =>
      data?.stats.books.map((item, index) => ({
        key: index,
        title: `${item.book.title} ${item.book.author}`,
        author: item.book.author,
        readers: item.book.readers_by_shelf[shelfName],
        url: item.book.url,
        cover: item.book.cover,
        timeline: item.book.timeline,
      })),
    [data, shelfName],
  );

  const lineChart = useCallback((timelineData) => {
    const timeline = timelineData
      .map((item) => {
        return {
          time: item.date_trunc.substring(0, 10),
          półki: parseInt(item.count),
        };
      })
      .reverse();
    const config = {
      data: timeline,
      height: 150,
      xField: 'time',
      yField: 'półki',
      label: {},
      point: {
        size: 5,
        shape: 'circle',
        style: {
          fill: 'white',
          stroke: colors.PRIMARY,
          lineWidth: 2,
        },
      },
      tooltip: {
        showMarkers: false,
        customContent: (title, items) => {
          return <TooltipContent title={title} items={items} viewsLabel={'Liczba półek'}></TooltipContent>;
        },
      },
      state: {
        active: {
          style: {
            shadowColor: colors.PRIMARY,
            shadowBlur: 4,
            stroke: 'transparent',
            fill: colors.PRIMARY,
          },
        },
      },
      lineStyle: {
        stroke: colors.PRIMARY,
        lineWidth: 4,
        lineJoin: 'round',
        lineCap: 'round',
      },
      theme: {
        geometries: {
          point: {
            diamond: {
              active: {
                style: {
                  shadowColor: colors.PRIMARY,
                  shadowBlur: 2,
                  stroke: colors.PRIMARY,
                },
              },
            },
          },
        },
      },
      interactions: [{ type: 'marker-active' }],
    };
    return <Line {...config} />;
  }, []);

  const columns = useMemo(
    () => [
      {
        title: 'Okładka',
        dataIndex: 'cover',
        key: 'cover',
        width: 120,
        render: (cover, record) => <Cover src={cover} url={record.url} />,
      },
      {
        title: 'Tytuł i autor',
        dataIndex: 'title',
        key: 'title',
        width: 230,
        sorter: (a, b) => a.title.localeCompare(b.title),
        ...getColumnSearchProps('title'),
        render: (text, record) => (
          <div>
            <a style={{ display: 'block', textWrap: 'wrap', whiteSpace: 'break-spaces', fontWeight: 600 }} href={record.url} target="_blank" rel="noopener noreferrer">
              {text}
            </a>
            <br />
            <span>{record.author}</span>
          </div>
        ),
      },
      {
        title: () => <div style={{ textWrap: 'wrap', whiteSpace: 'break-spaces' }}>{`Liczba użytkowników od momentu publikacji z książką na półce`}</div>,
        dataIndex: 'readers',
        key: 'readers',
        width: 120,
        sorter: (a, b) => a.readers - b.readers,
        render: (text) => <div>{text}</div>,
      },
      {
        title: <>{'Trend czytelniczy'}</>,
        dataIndex: 'timeline',
        key: 'timeline',
        width: 300,
        render: (text, record) => <div>{lineChart(record.timeline)}</div>,
      },
    ],
    [lineChart],
  );

  const table = useMemo(() => {
    return <Table dataSource={tableData} columns={columns} scroll={{ x: 720 }} />;
  }, [columns, tableData]);

  const fetchData = useCallback(async () => {
    const response = await getPublisherShelfStats(token, logout, daysBack, shelfNumber);
    setData(response);
  }, [daysBack, logout, shelfNumber, token]);

  useEffect(() => {
    if (visible && !data) {
      fetchData();
    }
  }, [fetchData, visible, data]);

  const titleWithTimeFrame = (
    <Space wrap>
      {modalTitle}
      <TimeFrameTag timeFrame={timeFrame} />
    </Space>
  );

  return (
    <>
      <CardTitle loading={loading} size={size} title={title} onClick={() => setVisible(true)}>
        {children}
      </CardTitle>
      <Modal
        width={900}
        title={titleWithTimeFrame}
        open={visible}
        okText="Zamknij okno"
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <SmallNote note="dane zbierane od dodania do serwisu Lubimyczytac.pl" />
        {data ? <pre>{table}</pre> : <p>Ładowanie danych...</p>}
      </Modal>
    </>
  );
};

export default CardTileWithModal;
